import React, { useState, useEffect, useRef } from "react";
import "./Guestbook.css";
import firebase from "../firebase.config";
import LikesCircleSVG from "../assets/svg/LikesCircle";
import LikesThumb from "../assets/svg/LikesThumb.js";
import swal from "sweetalert";
import { nanoid } from "nanoid";

function Guestbook(props) {
  //Variables
  const [{ guestName, guestMessage, guestVocation }, setGuestInfo] = useState({
    guestName: "",
    guestVocation: "",
    guestMessage: "",
    guestID: null,
  });

  const [loadingGuestbook, setLoadingGuestbook] = useState(true);
  const [Guestbook, setGuestbook] = useState([]);
  const [Likes, setLikes] = useState(null);
  const [alreadyLiked, setAlready] = useState("Like My Work?");

  const [
    { ChosenThemeOrange, ChosenThemeBlue, ChosenThemeSpace },
    setChosenTheme,
  ] = useState("");

  const [themeToPost, setThemeToPost] = useState("");

  const btnRef = useRef();

  let readFile = useRef();

  let globalID;

  //Functions

  useEffect(() => {
    fetchCollection("Guestbook");
    getTotalLikes();
  }, []);

  useEffect(() => {
    function onSubmit() {
      if (
        [guestName, guestVocation, guestMessage].includes("") ||
        [ChosenThemeSpace, ChosenThemeOrange, ChosenThemeBlue].includes(
          "Selected-Theme"
        ) === false
      ) {
        btnRef.current.setAttribute("disabled", "disabled");
        return false;
      } else {
        btnRef.current.removeAttribute("disabled", "disabled");
        return true;
      }
    }
    onSubmit();
  }, [
    guestName,
    guestVocation,
    guestMessage,
    ChosenThemeOrange,
    ChosenThemeBlue,
    ChosenThemeSpace,
  ]);

  //! Main functions

  async function fetchCollection(firebaseCollectionName) {
    //Length and document ID's of collection's documents
    let docLength = [];
    let jsxOutput = [];

    const db = firebase.firestore();
    db.collection(firebaseCollectionName)
      .get()
      .then((querySnapshot) => {
        //Prints out all guestbook data into JSX
        if (firebaseCollectionName === "Guestbook") {
          let allData = [];

          querySnapshot.forEach((doc) => {
            allData.push(doc.data());
          });

          //Render database values to JSX

          for (const [i, value] of allData.entries()) {
            jsxOutput.push(
              <div
                className={"Guest-Card-Container-" + value.theme}
                id={value.id}
                key={i}
              >
                {value.imgURL && (
                  <span className="Guest-Image-Container">
                    <img
                      className="Guest-Image"
                      loading="lazy"
                      src={value.imgURL}
                      alt=""
                    />
                    <div className={"Guest-Image-Circle-" + value.theme}></div>
                  </span>
                )}
                <span className="Guest-Name">{value.guestName}</span>
                <h6 className="Guest-Vocation">{value.guestVocation}</h6>

                <p className="Guest-Message">"{value.guestMessage}"</p>
                <span className="Guest-Date">{value.guestTimeStamp}</span>
                <span>
                  {localStorage.getItem("GuestbookID") === value.id && (
                    <i
                      className="fas fa-trash"
                      onClick={() => {
                        deleteSpecifiedDocumentAndImage();
                      }}
                    ></i>
                  )}
                </span>
              </div>
            );
          }
          setGuestbook(jsxOutput);
          setLoadingGuestbook(false);
        }
      });
    return [docLength];
  }

  async function postGuestDataToDataBase(firebaseCollectionName) {
    try {
      if (firebaseCollectionName === "Guestbook") {
        let imgURL = (await getImageURL()) || "";

        //Get date
        let guestTimeStamp = getDate();

        if (globalID) {
          const db = firebase.firestore();
          const guestbookCollection = db.collection("Guestbook");
          await guestbookCollection.doc(globalID).set({
            guestName,
            guestMessage,
            guestVocation,
            imgURL,
            guestTimeStamp,
            theme: themeToPost,
            id: globalID,
          });
        }
      }
    } catch (err) {
      console.error("Error: " + err);
      errorPost();
    }
    //Reset file
    addOrRemoveFile("removeFile");

    fetchCollection("Guestbook");
    succesfulPost();
  }

  async function imageUpload(e) {
    if (localStorage.getItem("GuestbookID")) {
      // ||
      //document.cookie.includes("RawExt:gr7QNI_YvrXLscV6Z2QIX")
      errorPost("postIsAlreadyThere");
      return false;
    } else {
      if (readFile.current.files[0] !== undefined || null) {
        globalID = nanoid(10);

        const fileID = globalID;

        localStorage.setItem("GuestbookID", fileID);
        document.cookie = `RawExt:gr7QNI_YvrXLscV6Z2QIX; name=Q2Z2a1; expires=Thu, 18 Dec 2099 12:00:00 UTC`;

        /// Points to the root reference
        var rootRef = firebase.storage().ref();
        var fileRef = rootRef.child(
          `guestBookImages/${fileID}/guestPic${fileID}`
        );

        //Upload file
        await fileRef.put(await addOrRemoveFile()).then((snapshot) => {});

        //Immediately get url for the file
        await getImageURL();

        // After the file has been uploaded and img URL has been, send rest of the form to the database
        postGuestDataToDataBase("Guestbook");
        console.log("The rest of the form has beeen sent");
      } else {
        //If no img file is selected
        globalID = nanoid(10);

        if (!localStorage.getItem("GuestbookID")) {
          const fileID = globalID;
          localStorage.setItem("GuestbookID", fileID);
          document.cookie = `RawExt:gr7QNI_YvrXLscV6Z2QIX; expires=Thu, 18 Dec 2099 12:00:00 UTC`;
        }

        postGuestDataToDataBase("Guestbook");
        console.log("No file selected, but posted to database");
      }
    }
  }

  async function getImageURL() {
    console.log(readFile.current.files[0]);
    if (readFile.current.files[0] !== undefined) {
      const fileID = globalID;
      const rootRef = firebase.storage().ref();

      const fileRef = rootRef.child(
        `guestBookImages/${fileID}/guestPic${fileID}`
      );

      const imgURL = await fileRef.getDownloadURL();
      console.log("The file URL:", imgURL);

      return imgURL;
    }
  }

  async function deleteSpecifiedDocumentAndImage() {
    if (navigator.onLine) {
      let guestID = localStorage.getItem("GuestbookID");

      const db = firebase.firestore();
      db.collection("Guestbook")
        .doc(guestID)
        .delete()
        .then(() => {
          console.log(`Document ${guestID} successfully deleted!`);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
          alert("Did you clear localstorage...? XD");
          return false;
        });

      //Delete localstorsge & cookies
      localStorage.removeItem("GuestbookID");
      document.cookie =
        "RawExt:gr7QNI_YvrXLscV6Z2QIX; expires=Thu, 01 Jan 1970 00:00:00 UTC";

      //Reset file selct display
      setShowUsersFileName("Choose a Guestbook picture");

      //Delete Image
      var imageStorageRef = firebase
        .storage()
        .ref()
        .child(`guestBookImages/${guestID}/guestPic${guestID}`);

      // Delete the file
      imageStorageRef
        .delete()
        .then(() => {
          console.log("image deleted");
        })
        .catch((error) => {
          console.log("No image  stored in database", error.message);
        });

      fetchCollection("Guestbook");
    } else {
      errorPost();
    }
  }

  //!Helper functions

  function getDate() {
    let todayDate = new Date().toISOString().slice(0, 10);
    return todayDate;
  }

  const [showUsersFileName, setShowUsersFileName] = useState(
    "Choose a Guestbook picture"
  );

  async function addOrRemoveFile(removeFile) {
    let hasUserSelectedAFile = readFile.current.files.length;
    let uploadedFile = "";

    if (hasUserSelectedAFile === 1 && !removeFile) {
      setShowUsersFileName(readFile.current.files[0].name);

      uploadedFile = readFile.current.files[0];
      return uploadedFile;
    }
    if (removeFile === "removeFile") {
      //Removes file
      document.querySelector("[type='file']").value = "";
      setShowUsersFileName("Choose a Guestbook picture");
      console.log("File removed");
    } else {
      return "";
    }
  }

  async function getTotalLikes() {
    //Get total likes
    firebase
      .firestore()
      .collection("Likes")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setLikes(doc.data().TotalLikes);
        });
      });
  }

  async function setTotalLikes() {
    let userHasLiked = localStorage.getItem("Has Liked");

    if (userHasLiked === "true") {
      setAlready("Already Liked😉");
    } else {
      localStorage.setItem("Has Liked", "true");

      const db = firebase.firestore();
      const likesCollection = db.collection("Likes");

      await likesCollection
        .doc("TotalLikes")
        .update({ TotalLikes: Likes + 1 })
        .then(getTotalLikes());
    }
  }

  function selectedTheme(e, theme) {
    switch (theme) {
      case "orange":
        setChosenTheme((prevValue) => ({
          ChosenThemeOrange: "Selected-Theme",
        }));
        setThemeToPost("Orange");
        break;
      case "blue":
        setChosenTheme((prevValue) => ({
          ChosenThemeBlue: "Selected-Theme",
        }));
        setThemeToPost("Blue");
        break;
      case "space":
        setChosenTheme((prevValue) => ({
          ChosenThemeSpace: "Selected-Theme",
        }));
        setThemeToPost("Space");
        break;

      default:
        break;
    }
  }

  function succesfulPost() {
    swal("Thank you!", "Scroll up to see your post!", "success");
  }
  function errorPost(isPostAlreadyThere) {
    swal(
      "Hmm...",
      "Post didn't go through, is your internet connection stable?",
      "error"
    );

    if (isPostAlreadyThere) {
      swal(
        "Hold up!",
        "Delete your previous guestbook to make a new one",
        "error"
      );
    }
  }

  return (
    <div className="Guest-Container">
      <h1 className="Big-Guestbook-Title">Guestbook Posts</h1>
      <div className="LikesSvgContainer">
        <div className="Likes-And-SVG-Responsive-Container">
          <div className="SVG-Responsive-Container">{LikesThumb()}</div>
          <span id="Likes">{Likes}</span>

          {/*Flexbox this container and set flex grow on likes number*/}
        </div>
        {LikesCircleSVG()}
        <button
          id="LikeButton"
          onClick={() => {
            setTotalLikes();
          }}
        >
          {alreadyLiked}
        </button>
      </div>
      {loadingGuestbook && <div>Loading...</div>}

      {Guestbook}

      <h1 className="Form-Header">
        <span>&nbsp;Leave a </span>
        <span>&nbsp;Guestbook </span>
        <span>&nbsp;post below!</span>
      </h1>
      {/*<p>Thank you for making it this far</p>*/}
      <form
        className="Guest-Form"
        onSubmit={(event) => {
          return [imageUpload(), event.preventDefault()];
        }}
      >
        <div className="Form-Name">
          <input
            className="Form-Name-Input"
            maxLength="25"
            type="text"
            onChange={(e) => {
              setGuestInfo((currentValue) => ({
                ...currentValue,
                guestName: e.target.value,
              }));
            }}
            placeholder="First Name"
            value={guestName}
          />
        </div>
        <div className="Form-Vocation">
          <input
            maxLength="20"
            type="text"
            className="Form-Vocation-Input"
            placeholder="Work Title"
            onChange={(e) => {
              setGuestInfo((currentValue) => ({
                ...currentValue,
                guestVocation: e.target.value,
              }));
            }}
          />
        </div>
        <div className="Form-Message">
          <textarea
            className="Form-Message-Textarea"
            maxLength="300"
            name="Form-Message"
            id="Guestbook-Form"
            cols="30"
            rows="10"
            placeholder="Message of your choice"
            onChange={(e) => {
              setGuestInfo((currentValue) => ({
                ...currentValue,
                guestMessage: e.target.value,
              }));
            }}
          ></textarea>
          {guestMessage && (
            <span className="Guest-Message-Counter">
              {300 - guestMessage.length} characters remaining
            </span>
          )}
        </div>

        <div className="Form-File-Container">
          <button
            className="Guestbook-File-Selector"
            onClick={(e) => {
              return [e.preventDefault(), readFile.current.click()];
            }}
          >
            Select file
          </button>
          <label className="Guestbook-Label-File-Selector" htmlFor="file">
            <span>{showUsersFileName}</span>
          </label>
          {showUsersFileName.includes("Choose a Guestbook picture") ===
            false && (
            <i
              onClick={() => addOrRemoveFile("removeFile")}
              className="fas fa-times"
            ></i>
          )}
          <input
            type="file"
            ref={readFile}
            onChange={() => addOrRemoveFile()}
            id="file"
            style={{ visibility: "hidden" }}
            accept="image/*"
          />
        </div>
        <div className="Guestbook-Theme-Container">
          <span
            onClick={(e) => {
              selectedTheme(e, "orange");
            }}
            className={"Guestbook-Theme-Orange " + ChosenThemeOrange}
          ></span>
          <span
            onClick={(e) => {
              selectedTheme(e, "blue");
            }}
            className={"Guestbook-Theme-Blue " + ChosenThemeBlue}
          ></span>
          <span
            onClick={(e) => {
              selectedTheme(e, "space");
            }}
            className={"Guestbook-Theme-Space " + ChosenThemeSpace}
          ></span>
        </div>
        <p className="Theme-Circle-Text">Choose your theme...</p>
        <button ref={btnRef} className="Guestbook-Button-Submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default Guestbook;

//async function fetchSpecifiedDocument() {
//  const db = firebase.firestore();
//  const docRef = db.collection("Guestbook").doc("1000");
//  docRef
//    .get()
//    .then((doc) => {
//      if (doc.exists) {
//        console.log("Document data:", doc.data());
//      } else {
//        // doc.data() will be undefined in this case
//        console.log("No such document!");
//      }
//    })
//    .catch((error) => {
//      console.log("Error getting document:", error);
//    });
//}
