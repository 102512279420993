/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Contact from "./components/Contact";
import PortfolioView from "./components/PortfolioView";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/contact" component={Contact}>
          <Contact />
        </Route>
        <Route path="/" component={PortfolioView}>
          <PortfolioView />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
