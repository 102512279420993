import React from "react";
import "./Contact.css";
import { randomBackground } from "../helpers/randomBackground";
import { Link } from "react-router-dom";

function Contact(props) {
  return (
    <React.Fragment>
      <div className="Link-Home">
        <Link to="/">
          <i className="fas fa-times" id="Exit-Contact"></i>
        </Link>
      </div>

      <div className="white">
        <p>Thank you for viewing my portfolifo. Let's talk further?</p>
        <div className="Form-Container">
          <form
            name="contact"
            data-netlify="true"
            action="/contact"
            method="POST"
            autoComplete="off"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p>Contact</p>
            <label>
              <input
                name="firstname"
                type="text"
                placeholder="Name"
                onClick={() => randomBackground()}
                id="nameInput"
              ></input>
            </label>

            <label>
              <input
                name="email"
                type="email"
                placeholder="Email"
                onClick={() => randomBackground()}
              ></input>
            </label>
            <div className="Form-Group">
              <label htmlFor=""></label>
              <textarea
                name="message"
                placeholder="Text..."
                className="Form-Control"
                rows="8"
                cols="50"
                onClick={() => randomBackground()}
              ></textarea>
            </div>
            <button type="submit" id="formButton">
              Submit
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Contact;
