import { gsap } from 'gsap';

export const randomBackground = () => {
	let color = ['aqua', 'red', 'green', 'orange', 'purple'];
	let random = () => {
		return Math.floor(Math.random() * 5);
	};
	gsap.to('.Form-Container, #formButton ', {
		backgroundColor: color[random()],
		duration: 10,
	});
};
