import firebase from 'firebase';
const firebaseConfig = {
	apiKey: 'AIzaSyCt3hFCopNXdY_AAP6FcnIBVS2uIpnlMhw',
	authDomain: 'carelwilliamsportfolio.firebaseapp.com',
	databaseURL: 'https://carelwilliamsportfolio-default-rtdb.europe-west1.firebasedatabase.app',
	projectId: 'carelwilliamsportfolio',
	storageBucket: 'carelwilliamsportfolio.appspot.com',
	messagingSenderId: '875831470266',
	appId: '1:875831470266:web:7dc6bb5dfe8ce77fe37a83',
	measurementId: 'G-Y87D3G8MXS',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
