import { useEffect } from "react";
import { gsap } from "gsap";
import { GSDevTools } from "gsap/GSDevTools";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";

gsap.registerPlugin(ScrollTrigger, TextPlugin, GSDevTools, ScrambleTextPlugin);

//Animations

export default function UseAnimation() {
  function scrollTrigger() {
    //Progressbar
    gsap.to("progress", {
      value: 100,
      ease: "none",
      scrollTrigger: { scrub: 0.3 },
    });
  }

  useEffect(() => {
    //Basic Skills
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".Basic-Skills-Section",
      },
    });
    tl.to(
      ".Big-Skills-Title",
      {
        text: "SKILLS ARE LOADING...",
        ease: "power1.in",
        duration: 3,
      },
      "-=1.2"
    )
      .to(".Big-Skills-Title", {
        text: "SKILLS",
        duration: 2,
      })
      .to(
        ".Skills-Wrapper",
        {
          autoAlpha: 1,
          opacity: 1,
          duration: 1.5,
          ease: "linear",
        },
        "<"
      )
      .to("#Click-Helper", { opacity: 1 }, "-=1.5");
  }, []);

  useEffect(() => {
    // Rocketman

    let tl = gsap.timeline();
    //GSDevTools.create();
    tl.fromTo(
      "#rocketman",
      { x: "-130vw", y: 0, autoAlpha: 1 },
      {
        duration: 1.4,
        x: "0vw",
        y: "-33.8vw",
      }
    )
      //Dialouge
      .to("#Looking", {
        autoAlpha: 1,
        delay: 0.5,
        duration: 0.5,
        x: "1vw",
        y: "-1vw",
        ease: "power1",
      })
      .to("#Looking", {
        autoAlpha: 0,
        delay: 3,
        duration: 0.5,
        ease: "power1",
      })
      .to("#My-Name-Is", {
        autoAlpha: 1,
        delay: 0.5,
        duration: 0.5,
        x: "1vw",
        y: "-1vw",
        ease: "power1",
      })
      .to("#My-Name-Is", {
        autoAlpha: 0,
        delay: 3,
        duration: 0.5,
        ease: "power1",
      })

      // Car-El Williams
      .fromTo(
        "#fullname",
        { x: "120vw", autoAlpha: 0 },
        {
          autoAlpha: 1,
          duration: 1.4,
          x: 0,
          ease: "back(1.5)",
          transformOrigin: "center",
        }
      )
      // Moves back
      .to(
        "#rocketman",
        {
          duration: 4,
          x: "-280vw",
          y: "-200vh",
          rotation: -360,
        },
        "-=.8237"
      )

      //Dialouge Whops

      .to(
        ".Whops-Container",
        {
          autoAlpha: 1,
          duration: 1,
        },
        "-=1.3"
      )
      .to(
        ".Whops-Container",
        {
          autoAlpha: 0,
          duration: 0.5,
          ease: "power1",
        },
        "+=1.5"
      )
      .to(".Ehm-Container", { autoAlpha: 1 }, "+=1")

      .to(
        ".Ehm-Container",
        {
          ease: "power1",
          duration: 0.5,
        },
        "+=1.5"
      )
      .set("#rocketman", {
        scale: 0.8,
        x: "-50vw",
        y: "-130vh",
      })
      .to("#rocketman", {
        scale: 0.8,
        x: "100vw",
        duration: 5,
        rotation: 5,
      })
      .to("#rocketman", {
        autoAlpha: 0,
      });

    //FrameWorks

    let ftl = gsap.timeline({
      scrollTrigger: {
        trigger: ".Big-Frameworks-Title",
        start: " 40%",
        bottom: " 60%",
      },
    });
    ftl
      .fromTo(
        ".Big-Frameworks-Title",
        { x: "-110vw" },
        {
          duration: 1.4,
          x: 0,
          ease: "back(1.5)",
        }
      )
      .fromTo(
        ".Framework-Section",
        {
          x: 0,
          opacity: 0.0,
          scale: 0.01,
        },
        {
          opacity: 1,
          scale: 1,
          duration: 4.4,
          x: 0,
          ease: "power3(-1)",
        },
        "-=1.1"
      )
      .fromTo(
        "#React-Native-Container",
        {
          x: 0,
          opacity: 0.0,
          scale: 0.01,
        },
        {
          opacity: 1,
          scale: 1,
          duration: 4.4,
          x: 0,
          ease: "power3(1)",
        },
        "-=2.8"
      );

    //Version Control

    gsap.fromTo(
      ".Big-Teamwork-Title",
      { x: "110vw" },
      {
        delay: 0.5,
        duration: 1.4,
        x: 0,
        ease: "back(1.5)",
        scrollTrigger: { trigger: ".Big-Teamwork-Title" },
      }
    );

    gsap.fromTo(
      "[id='Version Control']",
      { x: "110vw" },
      {
        delay: 0.8,
        duration: 1.2,
        x: 0,
        rotation: 360,
        ease: "back(1.5)",
        scrollTrigger: { trigger: '.Skills-Wrapper [id="Version Control"]' },
      }
    );
    gsap.fromTo(
      "#TypeScript",
      { x: "140vw" },
      {
        delay: 1.8,
        duration: 1.2,
        x: 0,
        rotation: 320,
        ease: "back(0.5)",
        scrollTrigger: { trigger: '.Skills-Wrapper [id="Version Control"]' },
      }
    );
  }, []);

  //Donify Slide

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    ScrollTrigger.defaults({
      toggleActions: "restart pause resume pause",
    });

    /* Main navigation */
    const panelsSection = document.querySelector("#panels");
    const panelsContainer = document.querySelector("#panels-container");
    document.querySelectorAll(".anchor").forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        const targetElem = document.querySelector(
          e.target.getAttribute("href")
        );
        if (
          targetElem &&
          panelsContainer.isSameNode(targetElem.parentElement)
        ) {
          const containerOffset =
            panelsSection.offsetTop + targetElem.offsetLeft;

          gsap.to(window, {
            scrollTo: {
              y: containerOffset,
              autoKill: false,
            },
            duration: 1,
          });
        } else {
          gsap.to(window, {
            scrollTo: {
              y: targetElem,
              autoKill: false,
            },
            duration: 1,
          });
        }
      });
    });

    /* Panels */
    const panels = gsap.utils.toArray("#panels-container .panel");
    gsap.to(panels, {
      xPercent: -100 * (panels.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: "#panels-container",
        pin: true,
        start: "top top",
        scrub: 1,

        end: () => "+=" + (panelsContainer.offsetWidth - window.innerWidth),
      },
    });
  }, []);

  //ScrolTrigger Animation

  useEffect(() => {
    scrollTrigger();
  }, []);

  //Krim Scanner Animation

  useEffect(() => {
    let overlay = gsap.timeline({
      scrollTrigger: {
        trigger: ".Krimscanner-Section",
        start: "top top",
        end: "60px",
        scrub: 1,
      },
    });
    overlay
      .fromTo(
        ".Krimscanner-Section",
        { opacity: 0 },
        { duration: 1, opacity: 0.95 }
      )
      .to(".krimTitle", { text: "#1 Krim Scanner", ease: "linear" }, "<");
  }, []);

  useEffect(() => {
    let krim = gsap.timeline({
      scrollTrigger: {
        trigger: ".krimParagraph",
        start: " top",
        end: " 10px",
        scrub: 1,
      },
    });
    krim.fromTo(
      ".Krimscanner-Section .Image-Container",
      { opacity: 0, y: -200 },
      { opacity: 1, ease: "back", y: 0, duration: 4.8 }
    );

    let krimFunc = gsap.timeline({
      scrollTrigger: {
        trigger: ".Krimscanner-Section .Image-Container button",
        start: "12%",
        end: " 10px",
        scrub: 1,
      },
    });
    krimFunc
      .fromTo(
        ".Krimscanner-Stats",
        { x: -500, opacity: 0 },
        { x: 0, opacity: 1 }
      )
      .fromTo(
        ".Krimscanner-Search",
        { x: 500, opacity: 0 },
        { x: 0, opacity: 1 },
        "<"
      );
  }, []);

  // Donify Animations

  useEffect(() => {
    //Hides Click Me on refresh
    gsap.fromTo("#Donify-Button", { autoAlpha: 0 }, { autoAlpha: 1 });

    //Figma, Vue and Git Stagger

    //const sections = gsap.utils.toArray('.panelNumber');
    //console.log(sections);
    //let maxWidth = 0;

    //const getMaxWidth = () => {
    //	maxWidth = 0;
    //	sections.forEach(section => {
    //		maxWidth += section.offsetWidth;
    //	});
    //};
    //getMaxWidth();
    //ScrollTrigger.addEventListener('refreshInit', getMaxWidth);
    //sections.forEach((sct, i) => {
    //	ScrollTrigger.create({
    //		trigger: '#panel-4',
    //		scrub: false,
    //		start: () =>
    //			'top top-=' +
    //			(sct.offsetLeft - window.innerWidth / 2) *
    //				(maxWidth / (maxWidth - window.innerWidth)),

    //		animation: gsap.fromTo(
    //			'.donify-Skills-Image-Container img',
    //			{ autoAlpha: 0 },
    //			{ autoAlpha: 1 }
    //		),
    //	});
    //});

    //gsap.fromTo(
    //	'.donify-Skills-Image-Container img',
    //	{
    //		scrollTrigger: {
    //			trigger: '#panel-4',
    //			start: sct =>
    //				'top top-=' +
    //				(sct.offsetLeft - window.innerWidth / 2) *
    //					(maxWidth / (maxWidth - window.innerWidth)),
    //			end: () => '+=' + (document.querySelector('#panel-4').offsetWidth / 3) * 1,
    //		},
    //	},

    //	{ opacity: 1, stagger: 1 }
    //);
  });

  //useEffect(() => {
  //  gsap.to(".fa-arrow-down", {
  //    y: 50,
  //    yoyo: true,
  //    duration: 2,
  //    ease: "linear",
  //    repeat: -1,
  //  });
  //}, []);
}
