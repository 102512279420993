import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
	name: 'counter',
	initialState: {
		CSS: 'Darken-Skills',
		javaScript: 'Darken-Skills',
		HTML: 'Lighten-Skills',
	},
	reducers: {
		oneClassCheck: (state, action) => {
			if (
				action.payload === 'HTML' &&
				(state.javaScript === 'Lighten-Skills' || 'Darken-Skills') &&
				(state.CSS === 'Lighten-Skills' || 'Darken-Skills')
			) {
				state.HTML = 'Lighten-Skills';
				state.javaScript = 'Darken-Skills';
				state.CSS = 'Darken-Skills';
			}

			if (
				action.payload === 'CSS' &&
				(state.javaScript === 'Lighten-Skills' || 'Darken-Skills') &&
				(state.HTML === 'Lighten-Skills' || 'Darken-Skills')
			) {
				state.HTML = 'Darken-Skills';
				state.javaScript = 'Darken-Skills';
				state.CSS = 'Lighten-Skills';
			}

			if (
				action.payload === 'JavaScript' &&
				(state.CSS === 'Lighten-Skills' || 'Darken-Skills') &&
				(state.HTML === 'Lighten-Skills' || 'Darken-Skills')
			) {
				state.HTML = 'Darken-Skills';
				state.CSS = 'Darken-Skills';
				state.javaScript = 'Lighten-Skills';
			}
		},
		incrementByAmount: (state, action) => {
			state.value += action.payload;
		},
	},
});

export const { oneClassCheck, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = amount => dispatch => {
	setTimeout(() => {
		dispatch(incrementByAmount(amount));
	}, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = state => state.counter.value;

export default counterSlice.reducer;
