/* eslint-disable jsx-a11y/anchor-is-valid */

import "./PortfolioView.css";
import Skills from "./Skills.js";
import TV from "./tv.js";
import Guestbook from "./Guestbook";
import React from "react";
import krimScanner from "../assets/videos/krimscanner.mp4";
import donify from "../assets/videos/donify.mp4";
import krimScannerImage from "../assets/images/krimscanner.jpg";
import statsImage from "../assets/images/stats.jpg";
import searchImage from "../assets/images/search.jpg";
import allaKat from "../assets/images/allakat.png";
import donifyLogo from "../assets/images/donifylogo.png";
import charities from "../assets/images/charities.png";
import figma from "../assets/images/figma.jpg";
import vue from "../assets/images/vue.jpg";
import git from "../assets/images/git.png";
import MobileView from "./MobileView";
import UseAnimation from "../helpers/animations";
import {
  nameSvg,
  textPopup,
  rocketMan,
  vueGradient,
  reactGradient,
  reactNativeGradient,
} from "../assets/svg/svg";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";

//import firebase from "firebase";

function PortfolioView(props) {
  function startDonify(event) {
    if (event.target.id === "Donify-Button") {
      document.querySelector("#Donify").play();
      document.querySelector("#Donify-Button").style.display = "none";
    } else {
      console.log("donify went wrong");
    }
  }

  const history = useHistory();

  function contactClick() {
    history.push("contact");
  }

  //UseAnimation contains all animation functions
  UseAnimation();

  return (
    <React.Fragment>
      <Router>
        <progress max="100" value="0"></progress>
        <MobileView />

        <div className="desktop">
          {nameSvg()}
          {rocketMan()}
          {textPopup()}

          <div className="Contact-Popup">
            <Link to="/contact">
              <svg
                onClick={contactClick}
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f)">
                  <circle
                    cx="22.5"
                    cy="22.5"
                    r="21.5"
                    fill="url(#paint0_linear)"
                  />
                  <circle
                    cx="22.5"
                    cy="22.5"
                    r="21.75"
                    stroke="#217FBC"
                    strokeWidth="1.5"
                  />
                </g>
                <path
                  d="M30.7 14H12.7C11.2 14 10 15.2 10 16.6V29.1C10 30.6 11.3 31.8 12.8 31.8H30.7C32.2 31.8 33.5 30.6 33.5 29.1V16.6C33.5 15.2 32.2 14 30.7 14ZM30.7 29.5H12.7C12.5 29.5 12.3 29.4 12.3 29.2V18.3L20.4 25.2C20.6 25.3 20.8 25.4 21 25.4H22.4C22.6 25.4 22.8 25.3 23 25.2L31.1 18.3V29.2C31.1 29.3 30.9 29.5 30.7 29.5ZM21.7 23L13.8 16.3H29.6L21.7 23Z"
                  fill="white"
                />
                <defs>
                  <filter
                    id="filter0_f"
                    x="0.3"
                    y="0.3"
                    width="44.4"
                    height="44.4"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="0.1"
                      result="effect1_foregroundBlur"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear"
                    x1="22.5"
                    y1="1"
                    x2="22.5"
                    y2="44"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2183BF" />
                    <stop offset="0.796381" stopColor="#19086D" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear"
                    x1="9.8833"
                    y1="16.0699"
                    x2="33.5496"
                    y2="29.7337"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.92" />
                  </linearGradient>
                </defs>
              </svg>
            </Link>
          </div>
          <section className="Basic-Skills-Section">
            <h1 className="Big-Skills-Title">SKILLS</h1>
            <div className="Skills-Wrapper">
              <Skills
                title="HTML"
                img="https://i.ibb.co/ZSMSgFL/html5.jpg"
                progressbar="70%"
                desc="You can't run before you can walk. Well, HTML and I have walked quite a bit, I'd say we are jogging now."
              />
              <Skills
                title="CSS"
                img="https://i.ibb.co/r5dYk3n/css-3.jpg"
                progressbar="79%"
                alt="css-2"
                border="0"
                desc="Oh beloved CSS, my first introduction to how cool the web can look. Of course I know you well!"
              />
              <Skills
                title="JavaScript"
                img="https://i.ibb.co/7W4GZDn/Group-15-2.jpg"
                progressbar="80%"
                desc="Simply put, JavaScript is just sublime! I know alot yet I have much to learn."
              />
            </div>

            <p
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: 70,
                opacity: 0,
              }}
              id="Click-Helper"
            >
              Click visible cards to see my skill level!
            </p>
          </section>
          <section>
            <h2 className="Big-Frameworks-Title">Frameworks</h2>
            <div className="Skills-Wrapper Framework-Section">
              {vueGradient()}

              <Skills
                title="Vue"
                img="https://i.ibb.co/pWWTLYh/final.png"
                progressbar="30%"
                desc="A great framework that I handle pretty well. Good job Evan You!"
              />

              <Skills
                title="React"
                img="https://i.ibb.co/vZqN5rX/Group-18.jpg"
                progressbar="80%"
                desc="Since last year my React knowledge has sky rocketed. React is awesome!"
              />
              {reactGradient()}
            </div>
            <div className="Skills-Wrapper" id="React-Native-Container">
              <Skills
                title="React Native"
                img="
                https://i.ibb.co/XSSpcXx/Frame-2-1.png"
                progressbar="100%"
                desc="Plenty of professional experience, great fun and 
                challenging, my favourite framework by far."
              />
              {reactNativeGradient()}
            </div>
          </section>
          <section>
            <h3 className="Big-Teamwork-Title">Teamwork</h3>

            <div className="Skills-Wrapper ">
              <Skills
                title="Version Control"
                img="https://i.ibb.co/tJdryCL/bild-6.png"
                progressbar="60%"
                desc="Whether I work alone or with fellow programmers I always use Git. I know all fundamentals."
              />
              <Skills
                title="TypeScript"
                img="https://miro.medium.com/max/816/1*TpbxEQy4ckB-g31PwUQPlg.png"
                progressbar="50%"
                desc="The easier code to read, the less headache the team gets. For this, TS is superb"
              />
            </div>
          </section>
          <h4 className="Big-Projects-Title">Projects</h4>

          <section className="Krimscanner-Section">
            <h5 className="Project-Headers krimTitle">&nbsp;</h5>
            <p className="Projects-Paragraph krimParagraph">
              “Inspired by the TV-series COPS, my very first project made in
              Vanilla JavaScript was based on Swedish Police's official crime
              API.“
            </p>

            <div className="Image-Container">
              <img
                loading="lazy"
                src={krimScannerImage}
                alt="Krimscanner Home page"
              />
              <button>
                <a href="https://github.com/Car-ElWilliams/Car-ElWilliams">
                  View On GitHub
                </a>
              </button>
            </div>
            <div className="Krimscanner-Feautres">
              <span className="Krimscanner-Stats">
                <p className="Krimscanner-Small-Title">
                  <strong>Stats</strong>
                </p>
                <img
                  loading="lazy"
                  src={statsImage}
                  alt="Krimscanner Home page"
                />
                <p>
                  <em>“See crime stats in your city”</em>
                </p>
              </span>
              <span className="Krimscanner-Search">
                <p className="Krimscanner-Small-Title">
                  <strong>Search</strong>
                </p>
                <img
                  loading="lazy"
                  src={searchImage}
                  alt="Krimscanner Home page"
                />
                <p>
                  <em>“Search for different type of crimes all over Sweden”</em>
                </p>
              </span>
            </div>
            <div id="Banner-Krimscanner"></div>
            <h6 className="Project-Headers" id="Short-Preview">
              Short Preview
            </h6>
            <div className="Krimscanner-Video-Container">
              <TV video={krimScanner} id="KrimScanner" audio="true" />
            </div>
            <p id="copyright">Music: Inner City - Bad Boys</p>
          </section>
          <div id="panels">
            <div id="panels-container">
              <div
                id="panel-2"
                className="panel full-screen orange panelNumber"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-6 d-flex flex-column">
                      <h2>
                        Make Donations Easier <br></br> With <span>Donify</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="panel-3"
                className="panel full-screen purple panelNumber"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-6 d-flex flex-column">
                      <p className="step-description Donify-Desc">
                        "Donify was made by me and four other classmates who
                        desired a new and better donation plattform"
                      </p>
                      <div className="donify-Image-Container">
                        <img loading="lazy" src={allaKat} alt="donify" />
                        <img loading="lazy" src={donifyLogo} alt="donify" />
                        <img loading="lazy" src={charities} alt="donify" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="panel-1" className="panel full-screen red panelNumber">
                <div className="container">
                  <div className="row">
                    <div>
                      <div className="Donify-Wrapper">
                        <TV video={donify} id="Donify" autoPlay="true" />
                        <button
                          id="Donify-Button"
                          onClick={(event) => {
                            startDonify(event);
                          }}
                        >
                          Click Me
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="panel-4"
                className="panel full-screen purple panelNumber"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-6 d-flex flex-column">
                      <p className="Donify-Desc-Small">
                        Designed in Figma and made with Vue for both desktop and
                        mobile versions. The task was to learn both Vue, Git,
                        Scrum and leadership skills.
                      </p>
                      <div className="donify-Skills-Image-Container">
                        <img loading="lazy" src={vue} alt="donify" />
                        <img loading="lazy" src={figma} alt="figma" />
                        <img loading="lazy" src={git} alt="git" />
                      </div>
                      <p id="credits">
                        *All videos and pictures except the tabbar were made by
                        me alone
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Guestbook />
      </Router>
    </React.Fragment>
  );
}
export default PortfolioView;
