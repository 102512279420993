import { randomBackground } from '../helpers/randomBackground';

function MobileView() {
	return (
		<div className="mobileView">
			<div>
				<h1>Not available for mobile yet</h1>
				<p>But feel free to contact me below if you need a webdeveloper</p>
			</div>
			<div className="Form-Container">
				<form
					name="contact"
					data-netlify="true"
					action="/contact"
					method="POST"
					autoComplete="off"
				>
					<input type="hidden" name="form-name" value="contact" />
					<p>Contact</p>
					<label>
						<input
							name="firstname"
							type="text"
							placeholder="Name"
							onClick={() => randomBackground()}
							id="nameInput"
						></input>
					</label>

					<label>
						<input
							name="email"
							type="email"
							placeholder="Email"
							onClick={() => randomBackground()}
						></input>
					</label>
					<div className="Form-Group">
						<label htmlFor=""></label>
						<textarea
							name="message"
							placeholder="Text..."
							className="Form-Control"
							rows="8"
							cols="50"
							onClick={() => randomBackground()}
						></textarea>
					</div>
					<button type="submit" id="formButton">
						Submit
					</button>
				</form>
			</div>
		</div>
	);
}

export default MobileView;
