/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useSelector, useDispatch } from "react-redux";
import { oneClassCheck } from "../features/counter/counterSlice.js";

import "./Skills.css";
import { gsap } from "gsap";
import "./Skills.css";

function Skills(props) {
  //Redux
  const { CSS, HTML, javaScript } = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  //Animation

  function hoverCardEnter(event) {
    if (event.target.id === "React") {
      console.log(event.target.id);
      gsap.to(`#${event.target.id}, #React-Gradient`, { scale: 1.1 });
    }

    if (event.target.id === "Vue") {
      console.log(event.target.id);
      gsap.to(`#${event.target.id}, #Vue-Gradient`, { scale: 1.1 });
    }
    if (event.target.id === "React Native") {
      console.log(event.target.id);
      gsap.to(`[id="React Native"], #React-Native-Gradient`, { scale: 1.1 });
    }
  }

  function hoverCardLeave(event) {
    if (event.target.id === "React") {
      console.log(event.target.id);
      gsap.to(`#${event.target.id}, #React-Gradient`, { scale: 1 });
    }
    if (event.target.id === "Vue") {
      console.log(event.target.id);
      gsap.to(`#${event.target.id}, #Vue-Gradient`, { scale: 1 });
    }
    if (event.target.id === "React Native") {
      console.log(event.target.id);
      gsap.to(`[id="React Native"], #React-Native-Gradient`, { scale: 1 });
    }
  }

  let progressBarPercentage = props.progressbar;

  function progressBar(event) {
    if (event.currentTarget.id === "HTML" && HTML !== "Darken-Skills") {
      gsap.fromTo(
        "#HTMLbar",
        { width: "0%" },
        { width: progressBarPercentage, duration: 0.8 }
      );
    }
    if (event.currentTarget.id === "CSS" && CSS !== "Darken-Skills") {
      gsap.fromTo(
        "#CSSbar",
        { width: "0%" },
        { width: progressBarPercentage, duration: 0.8 }
      );
    }
    if (
      event.currentTarget.id === "JavaScript" &&
      javaScript !== "Darken-Skills"
    ) {
      gsap.fromTo(
        "#JavaScriptbar",
        { width: "0%" },
        { width: progressBarPercentage, duration: 0.8 }
      );
    }

    if (event.currentTarget.id === "React") {
      gsap.fromTo(
        "#Reactbar",
        { width: "0%" },
        { width: progressBarPercentage, duration: 0.8 }
      );
    }
    if (event.currentTarget.id === "Vue") {
      gsap.fromTo(
        "#Vuebar",
        { width: "0%" },
        { width: progressBarPercentage, duration: 0.8 }
      );
    }

    if (event.currentTarget.id === "React Native") {
      gsap.fromTo(
        "[id='React Native'] .Moving-Level",
        { width: "0%" },
        { width: progressBarPercentage, duration: 0.8 }
      );
    }
    if (event.currentTarget.id === "Version Control") {
      gsap.fromTo(
        "#VersionControlbar",
        { width: "0%" },
        { width: progressBarPercentage, duration: 0.8 }
      );
    }
    if (event.currentTarget.id === "TypeScript") {
      gsap.fromTo(
        "#TypeScriptbar",
        { width: "0%" },
        { width: progressBarPercentage, duration: 0.8 }
      );
    }
  }

  //End

  //Other Functions
  //Toggles shaddow on card, uses Redux counterSlice file

  function toggleClass(event) {
    if (event.target.className === "Darken-Skills") {
      dispatch(oneClassCheck(event.target.id));
    }
  }

  function roateCard(event) {
    console.log(event.currentTarget.id);
    if (event.currentTarget.id === "TypeScript") {
      gsap.to(`#TypeScript, #TypeScript`, {
        rotate: 360,
      });
    }

    let timeline = gsap.timeline();

    timeline
      .fromTo(
        "#thankYou",
        { x: -30, y: 30, delay: 1 },
        { x: 0, y: 0, opacity: 1, delay: 1 }
      )
      .to("#thankYou", { opacity: 0, delay: 2 });
  }
  //JSX

  return (
    <div
      id={props.title}
      default={props.default}
      className="Skills-Container noselect"
      onClick={(event) => {
        toggleClass(event);
        progressBar(event);
        roateCard(event);
      }}
      onMouseOver={(event) => {
        hoverCardEnter(event);
      }}
      onMouseLeave={(event) => {
        hoverCardLeave(event);
      }}
    >
      {props.title === "TypeScript" && (
        <div
          id="thankYou"
          style={{
            bacgroundColor: "white",
            height: 100,
            position: "absolute",
            top: -50,
            right: -200,
            color: "white",
            fontSize: 20,
            opacity: 0,
          }}
        >
          Thank you needed that!
        </div>
      )}
      {props.title === "JavaScript" && (
        <div className={javaScript} id={props.title}></div>
      )}

      {props.title === "HTML" && <div className={HTML} id={props.title}></div>}
      {props.title === "CSS" && <div className={CSS} id={props.title}></div>}

      <h1 className="Skills-Title" style={{ color: props.txColor }}>
        {props.title || "JavaScript"}
      </h1>
      <img
        src={
          props.img ||
          "https://miro.medium.com/max/720/1*LjR0UrFB2a__5h1DWqzstA.png"
        }
        className="Skills-Image"
      ></img>
      <p className="Skills-Desc" style={{ color: props.txColor }}>
        <em>
          {props.desc ||
            '"With fundamental framewortk knolwedge in the backpack from Vue, learning React so far has been easy"'}
        </em>
      </p>
      <div className="Skills-Progress-Container">
        <svg
          className="Skills-Progress"
          viewBox="0 0 360 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame2">
            <rect
              id="Rectangle"
              x="0.539851"
              y="0.263973"
              width="100%"
              height="80%"
              rx="5%"
              fill="#fff"
              stroke="black"
              strokeWidth="0.298453"
            />
            <rect
              id={props.title.replace(" ", "") + "bar"}
              className="Moving-Level"
              x="0.1"
              y="0.1"
              width="0%"
              height="80%"
              rx="5%"
              fill="#fff"
              stroke="#3D2D00"
              strokeWidth="0.2"
            />
          </g>
        </svg>
      </div>
      <div className="Skills-Progress-Level">
        <p>Novice</p>
        <p>Veteran</p>
      </div>
    </div>
  );
}

export default Skills;
