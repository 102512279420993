import React from 'react';
import './tv.css';
function tv(props) {
	let overlay = true;
	function play(event) {
		let playKrimScanner = document.querySelector('#KrimScanner');
		let playCopsAudio = document.querySelector('#youtube-audio');
		let removeOverlay = document.querySelector('#Click-Krimscanner');

		if (document.querySelector('#KrimScanner').paused) {
			overlay = false;
			playKrimScanner.play();
			playCopsAudio.click();
			removeOverlay.style.display = 'none';
		}
		if (overlay === false && document.querySelector('#KrimScanner').play) {
			playCopsAudio.click();
		}
	}

	return (
		<div className="TV-Container">
			<div className="Video-Container">
				{props.id === 'Donify' && (
					<video
						id={props.id}
						onClick={event => play(event)}
						className="video1"
						src={props.video}
					></video>
				)}
				{props.id === 'KrimScanner' && (
					<div id="KrimScanner-Video-Start" onClick={event => play(event)}>
						<div id="Click-Krimscanner">
							<p>Click To View</p>
						</div>
						<video
							disablePictureInPicture
							id={props.id}
							className="video1"
							src={props.video}
						></video>
						<div
							data-video="on9TXY8kYyk"
							data-autoplay="0"
							data-loop="0"
							id="youtube-audio"
						></div>
					</div>
				)}
			</div>
		</div>
	);
}

export default tv;
